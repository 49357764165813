import Slider from "react-slick"
import { FaLinkedin } from "react-icons/fa";

const dataSpeakers = [
  {
    "avatar": require('../../../assets/images/bhi-speakers/roger-daglius.png'),
    "name": "Roger D. Dias",
    "occupation": "Director of Research and Innovation, Mass General Brigham, Harvard Medical School",
    "embed": "https://www.linkedin.com/in/rogerdagliusdias/"
  },
  {
    "avatar": require('../../../assets/images/bhi-speakers/stephen-brand.png'),
    "name": "Stephen Brand, EdM",
    "occupation": "Chief Imagination Officer at The New Enterprise Factory, MIT I-Corps Instructor",
    "embed": "https://www.linkedin.com/in/innovationatthecore/"
  },
  {
    "avatar": require('../../../assets/images/bhi-speakers/lee-schwamm.png'),
    "name": "Lee Schwamm, MD",
    "occupation": "VP of Virtual Care at Mass General Brigham, Director of Telehealth at MGH, Professor at Harvard Medical School",
    "embed": "https://www.linkedin.com/in/doctorleeschwamm/"
  },
  {
    "avatar": require('../../../assets/images/bhi-speakers/nevan-clancy.png'),
    "name": "Nevan Hanamura, PhD",
    "occupation": "Scientist and Entrepreneur at MIT",
    "embed": "https://www.linkedin.com/in/nevanhanumara/"
  },
  {
    "avatar": require('../../../assets/images/bhi-speakers/iavor-bojinov.png'),
    "name": "Iavor Bojinov, PhD",
    "occupation": "Assistant Professor at Harvard Business School, Former Data Scientist at Linkedin",
    "embed": "https://www.linkedin.com/in/iavor-bojinov/"
  },
  {
    "avatar": require('../../../assets/images/bhi-speakers/marc-succi.png'),
    "name": "Marc Succi, MD",
    "occupation": "Executive Director at MESH Incubator, Mass General Brigham, Founder/CEO at 2 Minute Medicine",
    "embed": "https://www.linkedin.com/in/marc-succi/"
  },
]

const BhiSpeakers = ({descritivo}) => {

  const ThreeColumnCarousel = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
          breakpoint: 1199,
          settings: {
              slidesToShow: 2
          }
      },
      {
          breakpoint: 767,
          settings: {
              slidesToShow: 1,
          }
      }
  ]
   
}

  return(
    <div className="container-fluid d-flex-align-items-center justify-content-around ptb--100">
      <div className="container text-center">
        <h3 className="h3" style={{color:'var(--color-gold)'}}>Speakers</h3>
        <div className='row text-center' dangerouslySetInnerHTML={{__html: descritivo}}></div>
      </div>
      <div className="container" style={{maxWidth:'1400px'}}>
        <Slider
        className="px-5 ms-5"
        {...ThreeColumnCarousel}>

          {dataSpeakers && dataSpeakers.map((item, i) => (
            <div className="col-3 p-2">
              <div key={i} className="speakers-card radius shadow bg-white text-center px-3 py-4">
              <div 
                className="radius m-auto" 
                style={{
                  backgroundColor:'#093366',
                  height:'150px',
                  width:'150px',
                  borderRadius:'50%'
                }}>
                  <img 
                  style={{
                    width:'100%', 
                    height:'100%', 
                    objectFit:'cover',
                    borderRadius:'50%'}} 
                  src={item.avatar} 
                  alt={item.name + ' avatar'}/>

                </div>
                <h5 className="mt-3" style={{color:'var(--color-gold)'}}>{item.name}</h5>
                <div className="ocupation-section">
                  <p className="fw-normal lh-base">{item.occupation}</p>
                </div>
                <a className="align-self-end" href={item.embed} target="_blank" rel="noreferrer">
                  <FaLinkedin style={{fontSize:'40px', color:'var(--color-gold)'}} />
                </a>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}
export default BhiSpeakers