import { useEffect, useState } from "react";
import SEO from "../../common/SEO";
import { useParams } from "react-router-dom";
import axios from "axios";
import HeaderTwo from "../../common/header/HeaderTwo";
import ImersaoHeroSection from "../bhi/components/ImersaoHeroSection";
import bhiVideo from '../../assets/images/bostonHeader.mp4'
import ImersaoCtaSection from "../bhi/components/ImersaoCtaSection";
import ImersaoHowItWorks from "../bhi/components/ImersaoHowItWorks";
import BhiSpeakers from "../bhi/components/BhiSpeakers";
import BhiFounders from "../bhi/components/BhiFounders";
import ImersaoAbout from "../bhi/components/ImersaoAbout";
import FooterOne from "../../common/footer/FooterOne";
import ImersaoInvest from "../bhi/components/ImersaoInvest";
import BhiHeader from "../bhi/components/BhiHeader";


export default function Imersoes(){
    const { slug } = useParams()
    const API_URL = 'https://api.academyabroad.com.br/api/site/'
    const [data, setData] = useState()


    useEffect(() => {
        axios.get(`${API_URL}imersoes/${slug}`).then(
            response => {
            console.log(response)
            setData(response.data)
            }
        ).catch((error) => console.log(error))
    },[])

    return(
        <>
            {data && (
            <>
                <SEO title={data.titulo}/>
                <HeaderTwo styles=" header-style-2" />
                <BhiHeader logo={data.upload_logo}/>
                <ImersaoHeroSection 
                    titulo={data.titulo}
                    descritivo={data.descricao}
                    card1={`Início do programa: ${data.data_programa}`}
                    card2={`Imersao presencial em Boston: ${data.data_presencial}`}
                    video={bhiVideo}
                />
                <ImersaoCtaSection 
                    titulo={data.cta_titulo}
                    descritivo={data.cta_descricao}
                    button={"Agendar reunião"}
                    action={data.cta_link}
                    slide={data.cta_slide_url}
                />
                <ImersaoHowItWorks
                    data={data} 
                />
                <BhiSpeakers
                descritivo={`<p class="mb-0 lh-1">Empreendedores que vão te mostrar o que já estão fazendo para revolucionar a área da saúde.</p>
                    <p "> Founders que participaram de edições anteriores do ${data.titulo}</p>`} />
                <BhiFounders 
                    descritivo={`<p class="mb-0 lh-1 text-white">Empreendedores que vão te mostrar o que já estão fazendo para revolucionar a área da saúde.</p>
                    <p class="text-white"> Founders que participaram de edições anteriores do ${data.titulo}</p>`}
                />
                <ImersaoAbout
                data={data}
                slide={data.sobre_slide_url} 
                />
                <ImersaoInvest 
                title={data.investimento_titulo}
                action={`/imersoes-faq/${data.slug}`}
                data={data.investimento_vantagens}
                valor={data.valor}
                />
                <FooterOne />
            </>
            )}
        </>
    )
}