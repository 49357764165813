import React, { useEffect, useState } from "react"
import './style/calendar.css'
import axios from "axios"
import './style/loading.css'
import { format, addMonths, subMonths  } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { ptBR } from 'date-fns/locale';
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const CalendarComponent = ({slug}) => {
    const [ loading, setLoading] = useState(false)
    const [dias, setDias] = useState(null)
    const [mes, setMes] = useState(converterDataString(new Date(), 1))
    const [selectedHours, setSelectedHours] = useState([])
    const [selectedHoursCheckout, setSelectedHoursCheckout] = useState([])
    const [hours, setHours] = useState(null)
    const [selectedDay, setSelectedDay] = useState();

    function converterDataString(dataString, meses) {
        var data = new Date(dataString);
        var ano = data.getFullYear();
        var mes = ('0' + (data.getMonth() + meses)).slice(-2);
        var dia = ('0' + data.getDate()).slice(-2); 
  
        var dataFormatada = ano + '-' + mes + '-' + dia;
    
        return dataFormatada;
    }
    
    function converterDataStringAno(dataString) {
        var data = dataString.split('-');
        var ano = data[0];
        var mes = data[1];
        var dia = data[2]; 
  
        var dataFormatada = `${dia}-${mes}-${ano}`;
    
        return dataFormatada;
    }

    function converterData(dataString) {
        var partes = dataString.split("-");
        var ano = partes[0];
        var mes = partes[1];
        var dia = partes[2];

        return ano + "," + parseInt(mes) + "," + parseInt(dia);
    }

    function fetchMes(mes){
        setLoading(true)
        axios.get(`https://api.academyabroad.com.br/api/site/mentor/${slug}/dias?mes=${mes} `)
        .then(response => {
            console.log(response)
            const convertedDates = response.data.dias.map(dateString => new Date(converterData(dateString)));
            setDias(convertedDates);
            setLoading(false)
        })
        .catch(error => console.log(error))
    }

    function fecthHoras(dia){
        axios.get(`https://api.academyabroad.com.br/api/site/mentor/${slug}/horarios?dia=${dia}`)
        .then(response => {
            console.log(response.data.horarios)
            setHours(response.data.horarios)
            setSelectedDay(dia)
        })
        .catch(error => console.log(error))
    }

    function handleNextMonth(){
        const nexMonth = addMonths(mes, 1)
        setDias(null)
        fetchMes(converterDataString(nexMonth, 1))
        setMes(converterDataString(nexMonth, 1))
    }

    function handlePrevMonth(){
        const prevMonth = subMonths(mes, 1)
        setDias(null)
        fetchMes(converterDataString(prevMonth, 1))
        setMes(converterDataString(prevMonth, 1))
    }

    useEffect(() => {
        fetchMes(mes)
    },[])

    function handleHoursSelected(date, hour){
        const formatedDate = `${converterDataStringAno(date)} | ${hour}`
        const formatedHoursCheckout = `${date} ${hour}`
        setSelectedHours([...selectedHours, formatedDate]);
        setSelectedHoursCheckout([...selectedHoursCheckout, formatedHoursCheckout]);
        setHours(prevHours => prevHours.filter(item => item !== hour));
    }

    function handleHoursUnselected(hour){
        let hourSplited = hour.split(' | ');
        const selectedHourIndex = selectedHours.findIndex(selectedHour => selectedHour === hour);
        if (selectedHourIndex !== -1) {
            setHours(prevHours => [...prevHours, hourSplited[1]]);
            setSelectedHours(prevHours => prevHours.filter(item => item !== hour));
            setSelectedHoursCheckout(prevHours => prevHours.filter(item => item !== selectedHoursCheckout[selectedHourIndex]));
        }
    }

    function handleEncode(data){

        const encodedString = data.join(',')

        const link = `https://painel.academyabroad.com.br/checkout/mentor/${slug}?horarios=${encodedString}`

        window.location.href =  link
        
    }

    const btnStyle = {
        border:'none',
        backgroundColor:'var(--color-primary)',
        color:'#FFFFFF',
        borderRadius:'25px'
    }

    const footer = (
        <div className="row">
            <button className="col-5" style={btnStyle} onClick={() => handlePrevMonth()}>
                <FaArrowLeft />
            </button>
            <span className="col"></span>
            <button className="col-5" style={btnStyle} onClick={() => handleNextMonth()}>
                <FaArrowRight />
            </button>
        </div>
      );


    const css = `
    .rdp-day_selected {
        background-color: #1780FF
    }

    .rdp-day_selected:hover{
        background-color: #093366
    }
    `


    


    return(
        <div className="col-12 mt-3">
            {!loading && dias ? (
                <>
                    <style>{css}</style>
                    <DayPicker
                    mode="single"
                    locale={ptBR}
                    disableNavigation
                    defaultMonth={mes}
                    selected={dias}
                    onSelect={setSelectedDay}
                    onDayClick={(e) => fecthHoras(converterDataString(e, 1))}
                    footer={footer}
                    />
                    {hours && (
                        <div className="row mt-3">
                            <h5 className="fs-4 text-primary my-2">{selectedDay && `Horários disponíveis em ${converterDataStringAno(selectedDay)}`}</h5>
                            {hours.sort().map((hour, index) => (
                                <div className="col-3 p-2 mt-2" key={index}>
                                    <span 
                                        className={`text-center rounded-pill px-4 py-2`} 
                                        style={{backgroundColor:'#1780FF40', color:'#1780FF', cursor:'pointer'}}
                                        onClick={() => handleHoursSelected(selectedDay, hour)}
                                        >{hour}
                                    </span>
                                </div>
                            ))}
                        </div>
                    )}

                    {selectedHours && selectedHours.length > 0 && (
                        <>
                            <div className="row mt-3">
                                <h5 className="fs-4 text-primary my-2">Horários selecionados</h5>
                                {selectedHours.sort().map((hour, index) => (
                                    <div className="col-12 p-2 mt-2" key={index}>
                                        <span 
                                            className={`text-center rounded-pill px-4 py-2`} 
                                            style={{backgroundColor:'#24753a40', color:'#24753a', cursor:'pointer'}}
                                            onClick={() => handleHoursUnselected(hour)}
                                            >{hour}
                                        </span>
                                    </div>
                                ))}
                            </div>

                            <div className="read-more-btn mt--35">
                                <button 
                                onClick={() => handleEncode(selectedHoursCheckout)}
                                className="edu-btn w-100 text-center" >
                                Agende sua mentoria
                                </button>
                            </div>
                        </>
                    )}
                </>
            ) : (
                <div className='loading'>
                    <div className='load-box'></div>
                    <div className='load-box-text'>Carregando calendário...</div>
                </div>
            )}
        </div>
    )
}

export default CalendarComponent