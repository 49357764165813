import React from 'react'
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';

const PrivacyPolicy = () => {
    return (
        <>
            <SEO title="Privacy Policy" />
            <Layout>
                {/* <BreadcrumbOne 
                    title="Privacy Policy"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Privacy Policy"
                /> */}
                <div className="edu-privacy-policy-area edu-privacy-policy edu-section-gap bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                            <div className="content">
                            
                                

<h5><strong>ACADEMY ABROAD</strong></h5>
<h6>POLÍTICAS DE PRIVACIDADE</h6>

<span className="text-justify">
  <p>Data da última atualização: 06 de dezembro de 2021</p>

  <p>Esta Política de Privacidade se aplica ao tratamento de dados pessoais feito pela Academy Abroad Serviços de Educação LTDA ("Academy Abroad").</p>

  <p>A Academy Abroad sabe que sua privacidade é importante para você e leva a privacidade do usuário muito a sério. Temos o compromisso de proteger seus <strong>dados pessoais</strong> (ou seja, qualquer informação que você nos forneça a partir da qual você possa ser identificado) de acordo com esta Política de Privacidade.</p> 

  <h4>QUAIS SÃO E COMO UTILIZAMOS OS SEUS DADOS</h4>
  <p>Quando você acessa e utiliza nosso site, assina nossa Plataforma, presta serviços para nós, ou quando de qualquer forma interage com a Academy Abroad, podemos realizar o tratamento de seus dados pessoais para diversas finalidades, a exemplo de algumas que são indicadas abaixo:</p>

  <table style={{width: '100%', marginBottom: 40, textAlign: 'left'}}>
    <tbody>
      <tr>
        <td style={{border: '1px solid rgba(0,0,0,.3)', padding: '20px'}}><strong>Dados Coletados</strong></td>
        <td style={{border: '1px solid rgba(0,0,0,.3)', padding: '20px'}}><strong>Finalidades</strong></td>
      </tr>

      <tr>
        <td style={{border: '1px solid rgba(0,0,0,.3)', padding: '20px'}}>Dados de cadastro em nossa newsletter ou em nossos eventos.</td>
        <td style={{border: '1px solid rgba(0,0,0,.3)', padding: '20px'}}>
          <ul style={{listStyle: 'disc', paddingLeft: 20}}>
            <li>
              Enviar conteúdo produzido pela Academy Abroad, como comunicados institucionais, newsletter, e-books, materiais sobre temas relacionados à estudar fora do país, convites para eventos ou pesquisa de feedback.
            </li>
            <li>
              Permitir a sua participação nos eventos promovidos pela Academy Abroad e sua interação.
            </li>
            <li>
              Cadastrar em nossa plataforma e direcionar as melhores oportunidades de intercâmbio.
            </li>
          </ul>
        </td>
      </tr>

      <tr>
        <td style={{border: '1px solid rgba(0,0,0,.3)', padding: '20px'}}>Dados de contato (nome, e-mail, instituição, telefone, endereço)</td>
        <td style={{border: '1px solid rgba(0,0,0,.3)', padding: '20px'}}>
          <ul style={{listStyle: 'disc', paddingLeft: 20}}>
            <li>
              Para responder suas dúvidas, elogios, reclamações, sugestões e nos comunicarmos com você, especialmente quando você nos envia uma mensagem pela página de por meio de uma denossas redes sociais.
            </li>
            <li>
              Para gerenciar nosso relacionamento com você, caso seja um estudante inscrito em nossa plataforma, um investidor interessado no business da Academy Abroad ou um representante de uma instituição parceira.
            </li>
            <li>
              Para gerenciar o faturamento e cobrança pelos nossos serviços.
            </li>
          </ul>
        </td>
      </tr>

      <tr>
        <td style={{border: '1px solid rgba(0,0,0,.3)', padding: '20px'}}>Dados fornecidos no seu currículo (nome, endereço, telefone, e-mail, histórico profissional, cargo almejado, objetivos,competências, bem como qualquer outra informação que você; espontaneamente incluir nos currículos que nos envia).</td>
        <td style={{border: '1px solid rgba(0,0,0,.3)', padding: '20px'}}>
          <ul style={{listStyle: 'disc', paddingLeft: 20}}>
            <li>
              Para recrutar novas pessoas para o time da Academy Abroad permitindo que participem de todas as etapas do processo seletivo. 
            </li>
            <li>
              Para avaliar o fit cultural do profissional à vagas abertas na Academy Abroad.
            </li>
            <li>
              Para a execução da política de inclusão da Academy.
            </li>
          </ul>
        </td>
      </tr>

      <tr>
        <td style={{border: '1px solid rgba(0,0,0,.3)', padding: '20px'}}>Dados de interação com o conteúdo produzido pela Academy Abroad (cliques em nosso site ou newsletter, número de acessos, abertura dos newsletter e informativos enviados, tempo depermanência, índice de engajamento, dados analíticos)</td>
        <td style={{border: '1px solid rgba(0,0,0,.3)', padding: '20px'}}>
          <ul style={{listStyle: 'disc', paddingLeft: 20}}>
            <li>
              Para melhorar nossa comunicação com você, desenvolver as funcionalidades de nosso site e serviços da nossa Plataforma.
            </li>
            <li>
              Para enviar conteúdo que acreditamos ser do seu interesse.
            </li>
            <li>
              Para realizar análises sobre a efetividade e pertinência do conteúdo que produzimos.
            </li>
          </ul>
        </td>
      </tr>

      <tr>
        <td style={{border: '1px solid rgba(0,0,0,.3)', padding: '20px'}}>Dados de prestadores de serviços e profissionais (dados de qualificação em Contratos, dados de contato)</td>
        <td style={{border: '1px solid rgba(0,0,0,.3)', padding: '20px'}}>
          <ul style={{listStyle: 'disc', paddingLeft: 20}}>
            <li>
              Para a gestão dos Contratos firmados com prestadores de serviços e profissionais parceiros, nos termos da legislação aplicável.
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td style={{border: '1px solid rgba(0,0,0,.3)', padding: '20px'}}>Dados bancários</td>
        <td style={{border: '1px solid rgba(0,0,0,.3)', padding: '20px'}}>
          <ul style={{listStyle: 'disc', paddingLeft: 20}}>
            <li>
              Para viabilizar o pagamento de colaboradores, de profissionais parceiros e de demais pessoas, físicas ou jurídicas, que contratem com a Academy.
            </li>
            <li>
              Para viabilizar o pagamento dos serviços prestados pela Academy
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td style={{border: '1px solid rgba(0,0,0,.3)', padding: '20px'}}>
            Dados de Cadastro em nossa plataforma (nome, e-mail, endereço, telefone, LinkedIn, instituição de ensino, nível dehabilidade com línguas, histórico profissional e objetivos profissionais)
        </td>
        <td style={{border: '1px solid rgba(0,0,0,.3)', padding: '20px'}}>
          <ul style={{listStyle: 'disc', paddingLeft: 20}}>
            <li>
              Para que você tenha acesso a nossa plataforma e para que possamos te direcionar as melhores oportunidades de intercâmbio.
            </li>
            <li>
              Para viabilizar a prestação do serviço de mentoria.
            </li>
          </ul>
        </td>
      </tr>
    </tbody>
  </table>

  <h4>COMPARTILHAMENTO DE DADOS COM TERCEIROS</h4>

  <p>Nós não fazemos tudo sozinhos! Utilizamos serviços de terceiros para hospedar nosso site e nosso e-mail, possibilitar a inscrição e o pagamento dos nossos cursos/serviços. Entender quais são as suas demandas por conteúdo/serviços e mapear a sua interação com o nosso site. Para todas essas atividades precisamos compartilhar seus dados com terceiros que nos auxiliam como prestadores de serviço. No entanto, você pode ficar tranquilo(a): só compartilhamos o que for estritamente necessário, de acordo com os parâmetros e garantias que apresentamos nesta Política.</p>

  <ul style={{listStyle: 'disc', paddingLeft: 20}}>
    <li>RD Station: <a href="https://www.rdstation.com/" target="_blank" rel="noopener noreferrer" alt="RD Station">Visitar site da RD Station</a></li>
    <li>Pagar.me: <a href="https://pagar.me/" target="_blank" rel="noopener noreferrer" alt="Pagar.me">Visitar Site do Pagar.me</a></li>
    <li>eNotas: <a href="https://enotas.com.br/" target="_blank" rel="noopener noreferrer" alt="eNotas">Visitar site do eNotas</a></li>
    <li>MongoDB: <a href="https://www.mongodb.com/pt-br" target="_blank" rel="noopener noreferrer" alt="MongoDB">Visitar site da MongoDB</a></li>
  </ul>

  <p>Podemos, ainda, compartilhar seus dados com:</p>

  <ul style={{listStyle: 'disc', paddingLeft: 20}}>
    <li>Nossos parceiros na realização de eventos</li>
    <li>Prestadores de serviços e fornecedores</li>
    <li>Autoridades Governamentais</li>
    <li>Com terceiros interessados em investir, combinar ou adquirir nossos negócios. Podemos compartilhar os seus dados pessoais com terceiros interessados no investimento, na combinação ou aquisição de nossos negócios, com os quais estivermos tratando sobre potenciais operações de investimento ou fusão, aquisição</li>
    <li>Para terceiros indicados por você.  Podemos compartilhar seus dados pessoais com terceiros que tenham sido indicados por você, mediante a sua solicitação.</li>
  </ul>

  <h4>POR QUANTO TEMPO SEUS DADOS SÃO TRATADOS</h4>

  <p>A Academy Abroad tem o compromisso de tratar os seus dados pessoais somente pelo período que for necessário para alcançar as finalidades específicas de cada tratamento, com a retenção dos dados pela menor duração possível, levando em consideração todas as obrigações legais e regulatórias existentes e a necessidade de defesa dos interesses da Academy Abroad em processos judiciais, administrativos ou arbitrais.</p>

  <p>Nesse sentido, os prazos de retenção de cada categoria de dados pessoais são definidos pela Academy Abroad de acordo com as características específicas de cada tratamento, incluindo a natureza dos dados, as finalidades para o tratamento, as obrigações legais ou regulatórias existentes, entre outros critérios relevantes para essa definição.</p>

  <p>Em determinados casos, temos obrigações legais que determinam a guarda dos dados pessoais por períodos específicos, a exemplo dos dados de seu endereço IP, hora e data de cada acesso ao nosso site, que precisam ser retidos pelo menos por 6 meses, em observância ao artigo 15 do Marco Civil da Internet.</p>

  <h4>TRANSFERÊNCIAS INTERNACIONAIS DE DADOS</h4>

  <p>Em certas circunstâncias, como quando utilizamos serviços de hospedagem em nuvem localizados em outros países, os seus dados pessoais poderão ser transferidos internacionalmente. Utilizamos serviços de terceiros que buscam adotar padrões adequados quanto à privacidade e proteção de dados pessoais.</p>

  <p>Nesses casos, adotaremos medidas apropriadas para proteger os seus dados pessoais, em conformidade com a legislação brasileira. Os dados pessoais transferidos para outros países também podem estar sujeitos às regras e legislações locais pertinentes.</p>

  <h4>COOKIES</h4>

  <p>Cookies são pequenos arquivos de texto que ficam armazenados em seu navegador ou em seu dispositivo e que são disparados quando você acessa grande parte dos sites disponíveis na internet, inclusive o nosso. Eles servem, entre outras finalidades, para permitir que suas preferências sejam reconhecidas e que o nosso site seja mostrado para você de acordo com essas preferências, a exemplo da língua em que ele é disponibilizado. Além disso, cookies podem ser utilizados para avaliar métricas de performance, identificar problemas de uso, permitir o funcionamento correto do site e coletar informações sobre o comportamento do usuário.</p>

  <p>Existem tipos distintos de cookies. Em nosso site, nós poderemos utilizar os seguintes cookies:</p>

  <ul style={{listStyle: 'disc', paddingLeft: 20}}>
    <li><strong>Cookies estritamente necessários.</strong> São essenciais para o funcionamento de nosso site, para garantir a sua segurança e performance adequada, sem os quais ele não funcionaria corretamente.</li>
    <li><strong>Cookies preferenciais.</strong> Armazenam informações sobre suas preferências, como, por exemplo, o seu idioma e sua região, permitindo uma melhor experiência de navegação no site.</li>
    <li><strong>Cookies de análise.</strong> Coletam informações sobre o seu comportamento durante a navegação em nosso site, como as páginas que são acessadas, o tempo de visita, as páginas pouco acessadas, de modo a nos permitir melhorar a qualidade do site.</li>
  </ul>

  <p>Caso você se oponha à sua utilização você pode recusar a instalação desses cookies ou removê-los do seu navegador ou dispositivo, com exceção dos cookies estritamente necessários, que são fundamentais para o funcionamento correto do site.</p>

  <p>Para mais informações sobre como remover cookies do seu dispositivo, você pode acessar os seguintes sites, de acordo com o navegador que você utiliza. Ao acessar os respectivos sites, você estará sujeito às políticas de privacidade de outras empresas, que podem não ser condizentes ou similares aos termos desta Política:</p>

  <ul style={{listStyle: 'disc', paddingLeft: 20}}>
    <li>Como remover cookies no <a href="https://support.google.com/accounts/answer/32050?co=GENIE.Platform%3DDesktop&hl=pt-BR" target="_blank" rel="noopener noreferrer" alt="Como remover cookies no Google Chrome">Google Chrome</a></li>
    <li>Como remover cookies no <a href="https://support.mozilla.org/pt-BR/kb/limpe-cookies-e-dados-de-sites-no-firefox" target="_blank" rel="noopener noreferrer" alt="Como remover cookies no Mozilla Firefox">Mozilla Firefox</a></li>
    <li>Como remover cookies no <a href="https://support.microsoft.com/pt-br/topic/como-excluir-arquivos-de-cookie-no-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc" target="_blank" rel="noopener noreferrer" alt="Como remover cookies no Internet Explorer">Internet Explorer</a></li>
    <li>Como remover cookies no <a href="https://support.apple.com/pt-br/HT201265" target="_blank" rel="noopener noreferrer" alt="Como remover cookies no Safari">Safari</a></li>
  </ul>

  <h4>SEGURANÇA DA INFORMAÇÃO</h4>

  <p>A Academy Abroad adota medidas técnicas e organizacionais, pautadas nas boas práticas do mercado, para proteger os seus dados pessoais contra acessos não autorizados ou situações ilícitas ou acidentais de destruição, perda, alteração, comunicação ou qualquer outra forma de tratamento inadequado. Seus dados são armazenados em um ambiente operacional segregado e seguro, que não é acessível ao público. Todas as práticas adotadas no ciclo de vida dos dados pessoais na Academy Abroad são pautadas em metodologias nacionais e internacionais – ABNT e ISO.</p>

  <p>Mesmo assim, não é possível garantir a segurança absoluta dos seus dados, especialmente contra-ataques perpetrados por pessoas mal intencionadas, que podem ser altamente sofisticados e inovadores, com técnicas e métodos desconhecidos até então mesmo pelas melhores ferramentas de segurança da informação.</p>

  <p>A segurança dos seus dados também depende da adoção de medidas razoáveis por você no uso de seus dispositivos e softwares. Caso você identifique ou tome conhecimento de algo que possa comprometer a segurança dos seus dados pessoais, ou de alguma possível vulnerabilidade em nosso site ou sistemas, por favor entre em contato conosco.</p>

  <h4>ALTERAÇÕES</h4>

  <p>A Academy Abroad poderá alterar esta Política a qualquer momento. Para facilitar, sempre que analisá-la, preste atenção ao início, onde destacamos a data da última atualização. Caso eventuais alterações sejam relevantes e ensejem a necessidade de novas  autorizações, nós o/a informaremos previamente e, se aplicável, garantiremos o consentimento devido.</p>

  <h4>EXERCÍCIO DE SEUS DIREITOS</h4>

  <p>Qualquer pessoa poderá solicitar o acesso aos seus dados pessoais quando estes forem armazenados e tratados pela Academy Abroad. Esses dados serão disponibilizados por e-mail em até dez dias úteis, desde que o usuário comprove ser quem realmente afirma ser com um documento válido e desde que informe um e-mail válido para envio da informação. Você ainda poderá solicitar o exercício de qualquer direito previsto pelo art. 18 da LGPD à Academy Abroad.</p>

  <p>Caso você receba comunicações de marketing da Academy Abroad, você pode cancelar o recebimento de futuros e-mails ou cancelar a participação em nossos programas através da página de "preferências", que pode ser acessada em todos os emails de marketing que você receber da Academy Abroad.</p>

  <p>Caso você tenha se registrado em nosso site, nossa plataforma ou tenha se candidatado a uma vaga na Academy Abroad e não deseje mais que seus dados sejam tratados conforme descrito nesta Política, ou se deseja acessar, retificar, cancelar ou se opor a tal processamento; caso você deseje fazer qualquer solicitação sobre seus dados pessoais ou ache que estamos tratando suas informações de maneira ilegal, entre em contato conosco.</p>

  <p>Qualquer situação relativa a dados pessoais deverá ser comunicada ao e-mail <a href="mailto:plataforma@academyabroad.com.br">plataforma@academyabroad.com.br</a>.</p>

  <p><strong>Os dados da Academy Abroad são:</strong><br/>
  ACADEMY ABROAD SERVIÇOS DE EDUCAÇÃO LTDA<br/>
  CNPJ 39.652.030/0001-18<br/>
  Rua Marambaia 687 . apto 1003 Bloco 2 . CEP 30.770-100 .  Belo Horizonte/MG</p>


</span>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default PrivacyPolicy;