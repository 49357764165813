import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Slider from "react-slick"
import icon7 from '../../../assets/images/icons/innovation.svg'
import icon8 from '../../../assets/images/icons/entrepreneuship.svg'
import icon9 from '../../../assets/images/icons/sofskill.svg'
import icon10 from '../../../assets/images/icons/base-learning.svg'


const ImersaoAbout = ({data, slide}) =>{

  const icones = [icon7, icon8, icon9, icon10]

  const imgSlide = slide.slice().reverse()


  const PrevArrow = ( props ) => {
    const { onClick } = props;
    return (
        <button className="slide-arrow prev-arrow" onClick={ onClick } >
            <FaChevronLeft className="icon-arrow-left-line" style={{color:'var(--color-extra01)'}} />
        </button>
    )
}

const NextArrow = (props) => {
    const { onClick } = props;
    return  (
        <button className="slide-arrow next-arrow" onClick={ onClick } >
            <FaChevronRight className="icon-arrow-left-line" style={{color:'var(--color-extra01)'}} />
        </button>
    )
}
  const ThreeColumnCarousel = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    autoplay: true,
    autoplaySpeed: 3000,
   
}


  return(
    <div className="container-fluid ptb--50">
      <div className="container">
        <h3 className="h3" style={{color:'var(--color-gold)'}}>{data.sobre_titulo}</h3>
        <p className="fw-bold">{data.sobre_descricao}</p>
        <div className="row ptb--50">
          <div className="col-12 col-lg-5">
          {data.sobre_modulos &&
              data.sobre_modulos.map((item, i) => (
                <div className='row d-flex gap-1' key={i}>
                  <div className="col-3 col-lg-2 d-flex align-items-start justify-content-center" >
                    <div className="radius p-3 mt-5" style={{backgroundColor:'var(--color-bhi-blue)'}}>
                      <img src={icones[i]} alt='icon' style={{width:"60px", height:"60px"}}/>
                    </div>
                  </div>
                  <div className='col'>
                    <span className="fs-5 lh-base mb-0 text-secondary">{`Módulo ${i + 1}`}</span>
                    <h5 className='my-0' style={{color:'var(--color-gold)'}}>{item.titulo}</h5>
                    <p className='fs-4 lh-sm fw-normal'>{item.descricao}</p>
                  </div>
                </div>
              ))
            }

          </div>
          <div className="col-12 col-lg-7" style={{height:'700px', marginTop:'170px'}}>
          <Slider
            className="slick-arrow-style-3 px-5"
            {...ThreeColumnCarousel}>
              {imgSlide.map((image, i) => (
                <div key={i}
                style={{
                  width:'700px',
                  height:'446px',
                  borderRadius:'13px'
                }}>
                  <img style={{
                    width:'100%',
                    height:'100%',
                    objectFit:'cover',
                    borderRadius:'13px'}} 
                    src={image} 
                    alt="slider" />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ImersaoAbout