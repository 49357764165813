import React from 'react'
import img1 from '../../assets/images/bhi.png'
import icon1 from '../../assets/images/icons/globe.svg'
import icon2 from '../../assets/images/icons/magic.svg'
import icon3 from '../../assets/images/icons/book.svg'
import icon4 from '../../assets/images/icons/urban.svg'
import icon5 from '../../assets/images/icons/learning.svg'
import icon6 from '../../assets/images/icons/graduate.svg'

const data = [
  {
    id: 1,
    icon: icon1,
    title: "Comece online",
    description: "8 semanas com sessões online e mentoria em grupo."
  },
  {
    id: 2,
    icon: icon2,
    title: "Visite os centros de inovação",
    description: "Visite os centros de pesquisa e conheça as startups que estão transformando a área de saúde."
  },
  {
    id: 3,
    icon: icon3,
    title: "5 dias de transformação",
    description: "Imersão no ecossistema de inovação e empreendedorismo em saúde de Boston nos Estados Unidos."
  },
  {
    id: 4,
    icon: icon4,
    title: "Aprenda com referências da área",
    description: "Profissionais dos principais polos de conhecimento do mundo, como Harvard e MIT, vão acompanhar sua jornada."
  },
  {
    id: 5,
    icon: icon5,
    title: "Metodologia ativa de aprendizagem",
    description: "Aprenda através de uma experiência imersiva em um dos maiores ecossistemas de saúde, inovação e empreendedorismo do mundo."
  },
  {
    id: 6,
    icon: icon6,
    title: "Construa sua comunidade",
    description: "A sua jornada de desenvolvimento apenas começa no BHI, após a imersão presencial criaremos a nossa comunidade online lifelong learning."
  }
]

export default function BhiSection(){
  return(
    <div className="container-fluid ptb--120">
      <div className="row d-flex justify-content-evenly">

        <div className="col-12 col-lg-4">
          <div className="col ms-sm-5 me-sm-2 d-flex flex-column justify-content-center justify-content-md-start">
            <h3 className="text-primary text-center text-md-start">Boston Healthcare Innovation</h3>
            <p className='fs-3 fw-lighter mb-5 text-center text-md-start'>Você é da área da saúde ou estudante e tem o interesse de vivenciar a medicina na prática e ainda ter contato com vários professores e CEOs que estão fazendo a diferença na saúde. E o melhor isso tudo em Boston, conhecido como o poso de saúde. Veja como irá funcionar:</p>
          </div>
          <img className='mt-5' src={img1} alt="imagem bhi" />
        </div>

        <div className="col-12 col-lg-5 d-flex flex-column justify-content-start mt-5 ms-5 me-2">
          {
            data.map((item) => (
              <div className='row d-flex gap-2' key={item.id}>
                <div className="col-1 d-flex align-items-start justify-content-center mt-3" >
                  <img src={item.icon} alt='icon' style={{width:"40px", height:"40px"}}/>
                </div>
                <div className='col'>
                  <h5 className='text-primary mb-0'>{item.title}</h5>
                  <p className='fs-4'>{item.description}</p>
                </div>
              </div>
            ))
          }
        </div>
      </div>
      <div className="row">
        <div className="col text-center">
          <h4>Apenas 50 Vagas!</h4>
          <p>Vagas disponíveis: 34</p>
          <a className='edu-btn' href='/imersoes/bhi-boston-healthcare-innovation'>Inscreva-se agora</a>
        </div>
      </div>
    </div>
  )
}