import { FaRegCalendar, FaPlane  } from "react-icons/fa";

const ImersaoHeroSection = ({titulo, descritivo, card1, card2, video}) => {
  return(
    <div 
    className="bhi-hero-section container-fluid m-0 p-0"
    style={{
      height:'717px',
    }}>

      <div 
        className="inner container-fluid text-center m-0 pt--120 px-0"
        style={{
          width:'100%',
          height:'100%',
          position:'relative',
          bottom:0,
          zIndex:1,
          backgroundColor:'#28305B99'
        }}
        >
          <div className="container">
            <h1 className="h3 text-light">{titulo}</h1>
            <div className='box-description-hero row' dangerouslySetInnerHTML={{__html: descritivo}}></div>
          </div>
          <div className="mt-5 d-flex flex-column align-items-center justify-content-center gap-3"
          >
            <div className="bhi-hero-card">
                <div className="col-1">
                  <FaRegCalendar className='icon-bhi-card' />
                </div>
                <div className="col">
                  <p className='mb-0 lh-normal'>{card1}</p>
                </div>
            </div>
            <div className="bhi-hero-card">
                <div className="col-1">
                  <FaPlane className='icon-bhi-card' />
                </div>
                <div className="col">
                  <p className='mb-0 lh-normal'>{card2}</p>
                </div>
            </div>
          </div>

      </div>
      <video
        autoPlay
        loop
        muted
        src={video}
        className="col-12"
        style={{
          position:'relative',
          zIndex:-1,
          top:-716,
          left:0,
          width:'100%',
          height: '100%',
          objectFit: 'cover',
          objectPosition:'bottom'
        }}
      ></video>
      
           
    </div>
  )
}

export default ImersaoHeroSection;