import React from 'react';
import img1 from '../../assets/images/logos/fhe.png';

export default function PartinersSection(){
  return(
    <div className="container pt--120 pb--200">
      <div className="row">
        <div className="col text-center">
          <h4 className='text-primary'>Parceiros da Academy Abroad</h4>
          <p>Grandes marcas que confiam no nosso poder de transformação</p>
          <div className="row d-flex justify-content-center">
            <div className="col-3 partiners-img-box">
              <img src={img1} alt="logo fhe"  />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}