import React, { useState, useEffect } from 'react';
import { FaSpinner, FaTimes, FaSearch, FaSlidersH, FaSortAmountDown, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { BiCategory } from 'react-icons/bi';
import CourseTypeOne from './CourseTypeOne';
import CourseData from '../../data/course/CourseData.json';
import axios from 'axios';
import CourseTypeOneAcademy from './CourseTypeOneAcademy';
import CardMentor from './CardMentor';
import _ from 'lodash';
import convertToSlug from '../convert-to-slug/convertToSlug';

const MentorFilter = ( { itemToShow, showLoadMore, incrementPerClick, cursos } ) => {
    const FilterControls = [...new Set( CourseData.map( item => item.filterParam ) ) ];
    FilterControls.unshift( 'All' );


    const [activeFilter, setActiveFilter] = useState( []);
    const [visibleItems, setVisibleItems] = useState( [] );
    const [dataRaw, setDataRaw] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [filteredName, setFilteredName] = useState();
    const [nameToShow, setNameToShow] = useState('');
    const [modal, setModal] = useState(false)

    useEffect( () => {
        //setActiveFilter( FilterControls[0].toLowerCase() );
        
        axios.get('https://api.academyabroad.com.br/api/site/mentor')
            .then(response => {
                let todos_mentores = _.shuffle(response.data.data);
                setDataRaw(todos_mentores);
                setVisibleItems(todos_mentores);

            })
            .catch(error => {
                console.error(error);
            });

        axios.get('https://api.academyabroad.com.br/api/site/categorias')
            .then(response => {
                setCategorias(response.data.data);
            })
            .catch(error => {
                console.error(error);
            });
    }, [] );


    const handleChange = ( filtro) => {
        console.log('change filtro', filtro);
        if(filtro.length === 0){
            setVisibleItems(dataRaw);
        }else{
            const filterd = dataRaw.filter( ( data ) => data.categorias.find(t=>{
                //console.log(t, filtro);
                return filtro.includes(t);
            })!=null );
            setNameToShow(filterd);
        }

    }

    const handleChangeCheck = ( e ) => {
        if(e.target.checked){
            let filtro = [
                ...activeFilter,
                e.target.value
            ];
            setActiveFilter(filtro);
            handleChange(filtro);
        }else{
            let filtro = activeFilter.filter( ( data ) => data !== e.target.value );
            setActiveFilter(filtro);
            handleChange(filtro);
        }        
    }

    

    const handleFilterName = (e, close) => {
        const inputText = e.target.value;
        setFilteredName(inputText.toLowerCase());
        const filteredItems = visibleItems.filter((item) =>
            item.nome.toLowerCase().includes(inputText.toLowerCase())
        );
        setNameToShow(filteredItems);

        close && setNameToShow('')
    }

    const handleClose = () => {
        setFilteredName('')
        setNameToShow('');
        setNameToShow(dataRaw);
    }

    const handleModal = () =>{
        !modal ? setModal(true) : setModal(false)
    }

    const handleOrdenation = () => {
  
        setActiveFilter(activeFilter)

        let newOrder = dataRaw.sort(function(a, b) {
            const nomeAutorA = a.nome.toUpperCase();
            const nomeAutorB = b.nome.toUpperCase();
            if (nomeAutorA < nomeAutorB) {
                return -1;
            }
            if (nomeAutorA > nomeAutorB) {
                return 1;
            }
            return 0;
        });
        setNameToShow(newOrder);
    }
   
    
    return (
        <div>
            <div className="row g-5 align-items-center mb--50 mt_md--30 mt_sm--30">
                <div className="col-lg-6">
                    <div className="section-title text-start">
                        <p className="mb-0 lh-sm fs-2 fw-bold" style={{color:'var(--color-secondary'}}>Veja os melhores</p>
                        <h3 className="title text-primary lh-sm">Mentores</h3>
                    </div>
                </div>
          
            </div>

            <div className="row g-5 d-flex flex-column-reverse flex-lg-row">
                <div className="col-lg-9 row" >

                    <div className="col-12 my-3">
                        <div className="edu-course-widget widget-shortby p-0" style={{backgroundColor:'transparent'}}>

                            <p className="fs-3 mb-0" style={{color:'var(--color-secondary', fontWeight:'600'}}>Pesquisar mentores</p>

                            <div className="row d-flex align-items-center gap-3 mx-1 mx-lg-0">

                                <div className="col-12 col-xl content d-flex align-items-center border-0 bg-white radius-small px-3 py-2 mt-0">
                                    <FaSearch className='mx-3 fs-3 text-primary'/>
                                    <input className='border-0' type='text' name='name-filter' value={filteredName} onChange={handleFilterName}/>
                                    {filteredName && <div className='px-3' onClick={handleClose}><FaTimes className='fs-3' /></div>}
                                </div>

                                {/* <div className='col col-lg col-xl-3 px-3 py-2 radius-small d-flex align-items-center gap-3'  style={{backgroundColor:'var(--color-secondary)'}}>
                                    <FaSlidersH className='text-white fs-1' />
                                    <p className="fs-3 mb-0 text-white">Filtrar pesquisa</p>
                                </div> */}

                                <div className='col col-lg col-xl-3 px-3 py-2 radius-small d-flex align-items-center gap-3'  
                                style={{backgroundColor:'var(--color-primary)'}}
                                onClick={handleOrdenation}>
                                    <FaSortAmountDown className='text-white fs-1' />
                                    <p className="fs-3 mb-0 text-white">Ordenar por</p>
                                </div>
                            </div>

                        </div>

                    </div>

                    {!nameToShow ?
                        visibleItems.map((item, index) => (
                            <div className="col-12 col-md-6 col-xl-4 mb--10" key={ index }>
                                <CardMentor data={item} />
                            </div>
                        ) ) :
                        nameToShow.map((item, index) => (
                            <div className="col-12 col-md-6 col-xl-4 mb--10" key={ index }>
                                <CardMentor data={item} />
                            </div>
                        ) )
                    }
                </div>

                <div className="col-lg-3 mt--0 mb-5">
                    <aside className="edu-course-sidebar">
                        <form onSubmit={ (e) => e.preventDefault() }>

                            <div className="edu-course-widget widget-shortby p-0 d-flex d-lg-none" style={{marginTop:'38px'}}>
                                <div className="col-12 inner radius-small">
                                    <div className='px-3 py-2 radius-small d-flex align-items-center justify-content-between w-100'  
                                    style={{backgroundColor:'var(--color-secondary)'}}
                                    onClick={handleModal}>
                                        <div className='d-flex align-items-center gap-3 cursor-pointer'>
                                            <BiCategory className='text-white fs-1' />
                                            <p className="fs-3 mb-0 text-white">Categorias</p>
                                        </div>
                                        {!modal ? <FaChevronDown className='text-white fs-1'/> : <FaChevronUp className='text-white fs-1'/>}
                                        
                                    </div>
                                    {modal && (
                                        <div className={`content px-3 mt-2 pb-5`}>
                                            <div>
                                                <div className="edu-form-check"  style={{height:'11px'}} >
                                                    <input 
                                                    type="checkbox" 
                                                    style={{height:'22px', outlineColor:'#093366'}} 
                                                    id={`todos`} 
                                                    value={"TODOS"} 
                                                    name="categoria" 
                                                    onChange={handleClose}/>
                                                    <label className='fs-4 lh-1 mt-3' htmlFor={`todos`}>TODOS</label>
                                                </div>
                                                {categorias.map( ( filter, i ) => (
                                                    <div className="edu-form-check" key={i}  style={{height:'11px'}} >
                                                        <input 
                                                        type="checkbox" 
                                                        style={{height:'22px', outlineColor:'#093366'}} 
                                                        id={`check-${i}`} 
                                                        value={filter.nome} 
                                                        name="categoria" 
                                                        onChange={handleChangeCheck}/>
                                                        <label className='fs-4 lh-1 mt-3' htmlFor={`check-${i}`}>{filter.nome}</label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="edu-course-widget widget-shortby p-0 d-none d-lg-flex" style={{marginTop:'38px'}}>
                                <div className="inner radius-small">
                                    <div className='px-3 py-2 radius-small d-flex align-items-center justify-content-between w-100'  
                                    style={{backgroundColor:'var(--color-secondary)'}}
                                    onClick={handleModal}>
                                        <div className='d-flex align-items-center gap-3 cursor-pointer'>
                                            <BiCategory className='text-white fs-1' />
                                            <p className="fs-3 mb-0 text-white">Categorias</p>
                                        </div>
                                        
                                    </div>
                                    <div className={`content px-3 mt-2 pb-5`}>
                                        <div>
                                            <div className="edu-form-check"  style={{height:'11px'}} >
                                                <input 
                                                type="checkbox" 
                                                style={{height:'22px', outlineColor:'#093366'}} 
                                                id={`todos`} 
                                                value={"TODOS"} 
                                                name="categoria" 
                                                onChange={handleClose}/>
                                                <label className='fs-4 lh-1 mt-3' htmlFor={`todos`}>TODOS</label>
                                            </div>
                                            {categorias.map( ( filter, i ) => (
                                                <div className="edu-form-check" key={i}  style={{height:'11px'}} >
                                                    <input 
                                                    type="checkbox" 
                                                    style={{height:'22px', outlineColor:'#093366'}} 
                                                    id={`check-${i}`} 
                                                    value={filter.nome} 
                                                    name="categoria" 
                                                    onChange={handleChangeCheck}/>
                                                    <label className='fs-4 lh-1 mt-3' htmlFor={`check-${i}`}>{filter.nome}</label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </aside>
                </div>
            </div>

          
        </div>
    )
}

export default MentorFilter;