import Slider from "react-slick"
import { FaLinkedin } from "react-icons/fa";


const dataSpeakers = [
  {
    "avatar": require("../../../assets/images/bhi-ceos/mahdi-ebnali.png"),
    "name": "Mahdi Ebnali, PhD",
    "occupation": "Co-Founder of XTORY",
    "embed": "https://www.linkedin.com/in/mebi/"
  },
  {
    "avatar": require("../../../assets/images/bhi-ceos/vitor-pamplona.png"),
    "name": "Vitor Pamplona, PhD",
    "occupation": "Founder and CEO of EyeNetra",
    "embed": "https://www.linkedin.com/in/vitorpamplona/"
  },
  {
    "avatar": require("../../../assets/images/bhi-ceos/charles-pozner.png"),
    "name": "Charles Pozner, MD, PhD",
    "occupation": "CMO at PC Institute for Medical Education",
    "embed": "https://www.linkedin.com/in/charles-pozner-b0117316/"
  },
  {
    "avatar": require("../../../assets/images/bhi-ceos/sean-matsuoka.png"),
    "name": "Sean Matsuoka, PhD",
    "occupation": "Co-Founder at General Prognostics (GPx)",
    "embed": "https://www.linkedin.com/in/seanmatsuoka/"
  },
  {
    "avatar": require("../../../assets/images/bhi-ceos/vivek-menon.png"),
    "name": "Vivek Menon, BA",
    "occupation": "Co-founder at ELITE HRV",
    "embed": "https://www.linkedin.com/in/vvkmenon/"
  },
]

const BhiFounders = ({descritivo}) => {

  const ThreeColumnCarousel = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
          breakpoint: 1199,
          settings: {
              slidesToShow: 2
          }
      },
      {
          breakpoint: 767,
          settings: {
              slidesToShow: 1,
          }
      }
  ]
   
}

  return(
    <div className="container-fluid d-flex-align-items-center justify-content-around ptb--100" style={{backgroundColor:'var(--color-bhi-blue)'}}>
      <div className="container text-center">
        <h3 className="h3" style={{color:'var(--color-gold)'}}>Founders</h3>
        <div className='row text-center' dangerouslySetInnerHTML={{__html: descritivo}}></div>
      </div>
      <div className="container" style={{maxWidth:'1400px'}}>
        <Slider
        className="px-5 ms-5"
        {...ThreeColumnCarousel}>

          {dataSpeakers && dataSpeakers.map((item, i) => (
            <div className="col-3 p-2">
              <div key={i} className="speakers-card radius shadow bg-white text-center px-3 py-4">
                <div 
                className="radius m-auto" 
                style={{
                  backgroundColor:'#093366',
                  height:'150px',
                  width:'150px',
                  borderRadius:'50%'
                }}>
                  <img 
                  style={{
                    width:'100%', 
                    height:'100%', 
                    objectFit:'cover',
                    borderRadius:'50%'}} 
                  src={item.avatar} 
                  alt={item.name + ' avatar'}/>

                </div>
                <h5 className="mt-3" style={{color:'var(--color-gold)'}}>{item.name}</h5>
                <div className="ocupation-section">
                  <p className="fw-normal lh-base">{item.occupation}</p>
                </div>
                <a className="align-self-end" href={item.embed} target="_blank" rel="noreferrer">
                  <FaLinkedin style={{fontSize:'40px', color:'var(--color-gold)'}} />
                </a>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}
export default BhiFounders