const FlagSection = () => {
    const dataLanguages = [
        {
            color: '#3C3B6E',
            flag: require('../../../assets/images/imersao-trilingue/flag - usa.png'),
            title: 'Inglês Intensivo',
            descritivo: ['Um mês de aulas focadas no inglês, com encontros semanais para aprendizado dinâmico e prático.', 'Opção de participar em grupo ou em aulas individuais, com valores diferenciados para cada modalidade.']
        },
        {
            color: '#AA151B',
            flag: require('../../../assets/images/imersao-trilingue/flag - esp.png'),
            title: 'Espanhol Encantador',
            descritivo: ['Explore o idioma espanhol em profundidade durante o segundo mês do programa.', 'Novamente, escolha entre aulas em grupo ou individuais para uma experiência personalizada.']
        },
        {
            color: '#FFCE00',
            flag: require('../../../assets/images/imersao-trilingue/flag - ale.png'),
            title: 'Alemão Acelerado',
            descritivo: ['Aprenda alemão de forma intensiva durante o terceiro mês da imersão.', 'Opte por aulas em grupo para interação social ou aulas individuais para foco total no aprendiza.']
        },

    ]
    return(
        <div className="container-fluid ptb--100" id="flag-section">
            <div className="container">
                <div className="row">
                {dataLanguages && dataLanguages.map((item, i) => (
                    <div className='col-12 col-md-4' key={i}>
                        <div className='card-language' style={{backgroundColor:`${item.color}`}}>
                            <div className='card-inner'>
                                <div className='row p-0 m-0'>
                                    <div className='col-4 '>
                                        <div className='box-image'>
                                            <img src={item.flag} alt={item.title} />
                                        </div>
                                    </div>
                                    <div className='col-8 '>
                                        <h5>{item.title}</h5>
                                    </div>
                                </div>
                                <div className='row'>
                                    <ul className='mx-3'>
                                        {item.descritivo.map((desc, i) => (
                                            <li key={i+100}>{desc}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}

                </div>
            </div>
        </div>
    )
}

export default FlagSection;