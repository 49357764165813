import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate   } from 'react-router-dom';
import ScrollToTop from './components/scrolltotop/ScrollToTop';
import ComingSoon from './pages/innerpages/ComingSoon';
import ContactMe from './pages/innerpages/ContactMe';
import CourseFilterAcademy from './pages/course/CourseFilterAcademy';
import CursoDetalhe from './pages/detailspages/CursoDetalhe';
import HomeOne from './pages/homepages/HomeOne';
import PrivacyPolicy from './pages/innerpages/PrivacyPolicy';
import Error from './pages/innerpages/Error';
import Mentores from './pages/course/Mentores';
import MentorDetalhes from './pages/detailspages/MentorDetalhes';

// Import Css Here 
import './assets/scss/style.scss';
import EditalIntercambio from './pages/course/EditalIntercambio';
import IntercambioDetails from './pages/detailspages/IntercambioDetails';
import Experiencia from './pages/course/Experiencia';
import ExperienciaDetalhe from './pages/blog/ExperienciaDetalhe';
import BlogList from './pages/course/BlogList';
import TermosDeUso from './pages/innerpages/TermosDeUso';
import BlogDetailAcademy from './pages/detailspages/BlogDetailAcademy';
import Impulso from './pages/impulso/Impulso';
import ParaSuaCarreira from './pages/carreira/ParaSuaCarreira';
import ParaSeuIntercambio from './pages/intercambio/ParaSeuIntercambio';
import ParaSeuNegocio from './pages/negocio/ParaSeuNegocio';
import Bhi from './pages/bhi';
import GiftCard from './pages/giftcard/GiftCardPage';
import ExperienceHunting from './pages/experiencehunting/ExperienceHunting';
import Palestra from './pages/palestra/Palestra';
import MissionUsa from './pages/mission-usa';
import ImersoesFaq from './pages/Imersoes-faq';
import MentoriasGrupo from './pages/course/MentoriasGrupo';
import MentoriaGrupoDetalhes from './pages/detailspages/MentoriaGrupoDetalhes';
import Imersoes from './pages/imersoes/[slug]';
import ProgramaTransicaodeCarreira from './pages/programa-transicao-de-carreira';
import ImersaoJoseBergossi from './pages/4-segredos-de-uma-comunicacao-de-sucesso';
import DescubraOIdiomaIdealComaAcademyAbroad from './pages/descubra-o-idioma-ideal-com-a-academy-abroad';


function App() {
    return (
        <Router>
            <ScrollToTop>
                <Routes>
                    <Route exact path='/' element={<HomeOne/>}/>
                    {/* <Route exact path='/' element={<ComingSoon/>}/> */}

                    <Route exact path='/home' element={<HomeOne/>}/>

                    <Route exact path={`${process.env.PUBLIC_URL}/cursos`} element={<CourseFilterAcademy/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/curso/:slug`} element={<CursoDetalhe/>}/>

                    <Route exact path={`${process.env.PUBLIC_URL}/mentores`} element={<Mentores/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/mentor/:slug`} element={<MentorDetalhes/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/mentor/:slug/:exclusive`} element={<MentorDetalhes/>}/>

                    <Route exact path={`${process.env.PUBLIC_URL}/mentorias-em-grupo`} element={<MentoriasGrupo/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/mentorias-em-grupo/:slug`} element={<MentoriaGrupoDetalhes/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/mentorias-em-grupo/:slug/:exclusive`} element={<MentoriaGrupoDetalhes/>}/>

                    <Route exact path={`${process.env.PUBLIC_URL}/editais-intercambio`} element={<EditalIntercambio/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/editais-intercambio/:uuid`} element={<IntercambioDetails/>}/>

                    <Route exact path={`${process.env.PUBLIC_URL}/experiencias`} element={<Experiencia/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/experiencias/:uuid`} element={<ExperienciaDetalhe/>}/>

                    <Route exact path={`${process.env.PUBLIC_URL}/blog`} element={<BlogList/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/blog/:uuid`} element={<BlogDetailAcademy/>}/>

                    <Route exact path={`${process.env.PUBLIC_URL}/contato`} element={<ContactMe/>}/>

                    <Route exact path={`${process.env.PUBLIC_URL}/gift-card`} element={<GiftCard />}/>

                    <Route exact path={`${process.env.PUBLIC_URL}/em-breve`} element={<ComingSoon/>}/>

                    <Route exact path={`${process.env.PUBLIC_URL}/impulso`} element={<Impulso />}/>

                    <Route exact path={`${process.env.PUBLIC_URL}/para-sua-carreira`} element={<ParaSuaCarreira />}/>

                    <Route exact path={`${process.env.PUBLIC_URL}/para-seu-intercambio`} element={<ParaSeuIntercambio />}/>

                    <Route exact path={`${process.env.PUBLIC_URL}/para-seu-negocio`} element={<ParaSeuNegocio />}/>

                    <Route exact path={`${process.env.PUBLIC_URL}/termos-de-uso`} element={<TermosDeUso/>}/>

                    <Route exact path={`${process.env.PUBLIC_URL}/experience-hunting`} element={<ExperienceHunting/>}/>

                    <Route exact path={`${process.env.PUBLIC_URL}/politicas-de-privacidade`} element={<PrivacyPolicy/>}/>

                    <Route exact path={`${process.env.PUBLIC_URL}/palestra/:slug`} element={<Palestra />}/>

                    <Route exact path={`${process.env.PUBLIC_URL}/imersoes/:slug`} element={<Imersoes />}/>

                    <Route exact path={`${process.env.PUBLIC_URL}/bhi`} element={<Navigate to="/imersoes/bhi-boston-healthcare-innovation" />}/>

                    <Route exact path={`${process.env.PUBLIC_URL}/mission-usa`} element={<Navigate to="/imersoes/fcj-investment-mission-usa" />}/>

                    <Route exact path={`${process.env.PUBLIC_URL}/imersoes-faq/:slug`} element={<ImersoesFaq />}/>

                    <Route exact path={`${process.env.PUBLIC_URL}/programa-transicao-de-carreira`} element={<ProgramaTransicaodeCarreira />}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/4-segredos-de-uma-comunicacao-de-sucesso-com-jose-bergossi`} element={<ImersaoJoseBergossi />}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/descubra-o-idioma-ideal-com-a-academy-abroad`} element={<DescubraOIdiomaIdealComaAcademyAbroad />}/>


                    <Route path='*' element={<Error/>}/>
                </Routes>
            </ScrollToTop>
        </Router>
    );
}

export default App;
