import React from 'react';
import { Link } from 'react-router-dom';
import ScrollTopButton from './ScrollTopButton';
import { FaInstagram, FaFacebookF, FaLinkedinIn, FaChevronRight, AiFillYoutube, FaYoutube } from "react-icons/fa";

const FooterOne = () => {
    return (
        <>
            <footer className="container-fluid pt--100" style={{backgroundColor:'#093366'}}>
                <div className="footer-top ">
                    <div className="container eduvibe-animated-shape d-flex align-items-center flex-column">

                        <div className="container-fluid row">
                            <div className="logo" style={{height:'200px'}}>
                                <Link to={process.env.PUBLIC_URL + "/"}>
                                    <img className="logo-light" src="/images/logos/logo-white.png" alt="Footer Logo" width={260} height={76} />
                                </Link>
                            </div>
                        </div>

                        <div className="row g-5 d-flex justify-content-between">

                            <div className="col-lg-3 col-12">
                                <div className="edu-footer-widget">
                                    <h5 className="text-primary">Sobre Nós</h5>
                                    <p className="text-white fs-4 fw-light lh-5">A Academy Abroad é uma plataforma de impulsionamento de carreira, com o foco na preparação para oportunidades educacionais e profissionais, dentro e fora do Brasil.</p>
                                    <h5 className="widget-title text-primary mt--100">Siga nossas redes sociais</h5>
                                    <ul className="social-share">

                                        <li className='bg-white rounded-circle' style={{height:'50px', width:'50px'}}>
                                            <a className='rounded-circle' style={{lineHeight:'50px'}} href="https://web.facebook.com/AcademyAbroad" target='_blank' rel="noreferrer">
                                                <FaFacebookF  className='text-primary' />
                                            </a>
                                        </li>

                                        <li className='bg-white rounded-circle' style={{height:'50px', width:'50px'}}>
                                            <a className='rounded-circle' style={{lineHeight:'50px'}} href="https://www.instagram.com/academy.abroad" target='_blank' rel="noreferrer">
                                                <FaInstagram className='text-primary' />
                                            </a>
                                        </li>

                                        <li className='bg-white rounded-circle' style={{height:'50px', width:'50px'}}>
                                            <a className='rounded-circle' style={{lineHeight:'50px'}} href="https://www.youtube.com/@AcademyAbroad" target='_blank' rel="noreferrer">
                                                <FaYoutube className='text-primary'/>
                                            </a>
                                        </li>

                                        <li className='bg-white rounded-circle' style={{height:'50px', width:'50px'}}>
                                            <a className='rounded-circle' style={{lineHeight:'50px'}} href="https://www.linkedin.com/company/academyabroad/" target='_blank' rel="noreferrer">
                                                <FaLinkedinIn className='text-primary'/>
                                            </a>
                                        </li>

                                        {/* <li><a href="#"><i className="icon-Instagram"></i></a></li> */}
                                    </ul>
                                </div>
                            </div>

                            {/* <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="edu-footer-widget explore-widget">
                                    <h5 className="widget-title">Explore</h5>
                                    <div className="inner">
                                        <ul className="footer-link link-hover">
                                            <li><Link to="/about-us-1"><i className="icon-Double-arrow"></i>About Us</Link></li>
                                            <li><Link to="/event-grid"><i className="icon-Double-arrow"></i>Upcoming Events</Link></li>
                                            <li><Link to="/blog-standard"><i className="icon-Double-arrow"></i>Blog & News</Link></li>
                                            <li><Link to="/faq"><i className="icon-Double-arrow"></i>FAQ Question</Link></li>
                                            <li><Link to="/testimonial"><i className="icon-Double-arrow"></i>Testimonial</Link></li>
                                            <li><Link to="/privacy-policy"><i className="icon-Double-arrow"></i>Privacy Policy</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div> */}

                            <div className="col-lg-3 col-sm-6">
                                <div className="edu-footer-widget quick-link-widget">
                                    <h5 className="widget-title text-primary">Links Úteis</h5>
                                    <div className="inner">
                                        <ul className="footer-link link-hover list-unstyled">
                                            <li><Link className='text-white fw-light' to="/cursos"><FaChevronRight className='text-primary' /> Cursos</Link></li>
                                            <li><Link className='text-white fw-light' to="/mentores"><FaChevronRight className='text-primary' /> Mentores</Link></li>
                                            <li><Link className='text-white fw-light' to="/editais-intercambio"><FaChevronRight className='text-primary' /> Editais de intercâmbio</Link></li>
                                            <li><Link className='text-white fw-light' to="/experiencias"><FaChevronRight className='text-primary' /> Experiências</Link></li>
                                            <li><Link className='text-white fw-light' to="/termos-de-uso"><FaChevronRight className='text-primary' /> Termos de Uso</Link></li>
                                            <li><Link className='text-white fw-light' to="/politicas-de-privacidade"><FaChevronRight className='text-primary' /> Políticas de Privacidade</Link></li>
                                            <li><Link className='text-white fw-light' to="/contato"><FaChevronRight className='text-primary' /> Contato</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-sm-6">
                                <div className="edu-footer-widget">
                                    <h5 className="widget-title text-primary">Informações de contato</h5>
                                    <div className="inner">
                                        <div className="widget-information">
                                            <ul className="information-list list-unstyled">
                                                <li className='text-white fw-light'>
                                                    <i className="icon-map-pin-line text-primary"></i> R. Marambaia, 687 - Caiçaras, Belo Horizonte 
                                                </li>
                                                <li>
                                                    <i className="icon-phone-fill text-primary"></i>
                                                    <a  className='text-white fw-light' href="tel: (31) 99561-3002"> (31) 99561-3002</a>
                                                </li>
                                                <li>
                                                    <i className="icon-mail-line-2 text-primary"></i>
                                                    <a className='fs-5 text-white fw-light' target="_blank" href="mailto:plataforma@academyabroad.com.br" rel="noreferrer"> plataforma@academyabroad.com.br</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column align-items-center flex-md-row gap-3 mt--40 mb-5' style={{minHeight:'104px', width:'150px'}}>
                                        <img src="/images/inovativa.png" alt='inovativa' style={{height: '100%', width:'100%'}}/>
                                        <img src="/images/outreach.png"  alt='inovativa' style={{height: '100%', width:'100%'}}/>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="shape-dot-wrapper shape-wrapper d-md-block d-none">
                            <div className="shape-image shape-image-1">
                                <img src="/images/shapes/shape-21-01.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-2">
                                <img src="/images/shapes/shape-35.png" alt="Shape Thumb" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright-area copyright-default">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <p>Copyright 2023 <a href="#">Academy Abroad</a>. All Rights
                                        Reserved</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <ScrollTopButton />
        </>
    )
}

export default FooterOne;