import img2 from '../../../assets/images/imersao-trilingue/ativo-banner.png'

const Banner1 = () => {
    return(
        <div className="container-fluid ptb--50" id="banner1">
            <div className="container">
                <div className='row py-5'>
                    <div className='col-12'>
                        <div className='banner-1 mx-auto'>
                            <div className='row'>
                                <div className='col-6'>
                                    <div className='banner-box-image'>
                                        <img src={img2} alt='imagem banner' />
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='py-5'>
                                        <p>Se você sempre quis aprender novos idiomas ou aprimorar suas habilidades linguísticas, o Programa de Imersão Trilíngue com Scott é perfeito para você! Este programa exclusivo oferece a oportunidade de mergulhar em três línguas populares - inglês, espanhol e alemão - ao longo de três meses, permitindo que você escolha o idioma que mais lhe interessa ao final.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner1