import React from 'react';
import SEO from '../../common/SEO';
import HeaderTwo from '../../common/header/HeaderTwo';
import BannerOne from '../../components/banner/BannerOne';
import HomeOneAbout from '../../components/home-one/HomeOneAbout';
import FooterOne from '../../common/footer/FooterOne';
import CallToActionThree from '../../components/cta/CallToActionThree';
import HowItWorksTwo from '../../components/how-it-works/HowItWorksTwo';
import FunFacts from '../../components/fun-facts/FunFacts';
import CourseCarouselTwo from '../course/CourseCarouselTwo';
import NewsletterThree from '../../components/newsletter/NewsLetterThree';
import CourseCarouselThree from '../course/CourseCarouselThree';
import TestimonialSectionFive from '../../components/testimonial-section/TestimonialSectionFive';
import CallToActionFour from '../../components/cta/CallToActionFour';
import BhiSection from '../../components/bhi-section/BhiSection';
import PartinersSection from '../innerpages/PartinersSection';
import imgElement from "../../assets/images/bg/elemento-horizontal.png"
import img1 from '../../assets/images/carreira.jpg'
import img2 from '../../assets/images/phone-2.png'
import PopUp from '../../components/popup/popup';
import GiftCardSection from '../../components/gift-card-section/GiftCardSection';
import Embaixador from '../../components/embaixador/Embaixador';

const todos = ["INTERCÂMBIO","CARREIRA", "BUSINESS", "INOVAÇÃO", "PESQUISA", "SAÚDE", "ENGENHARIA", "DIREITO", "TI", "COMERCIAL", "MARKETING", "IDIOMAS", "ORATÓRIA", "PREPARAÇÃO PARA PROVAS"]

const HomeOne = () => {
    return (
        <>
            <SEO title="Home" />

            {/* <PopUp /> */}

            <HeaderTwo styles="header-style-2" />

            <BannerOne 
                title="Aperfeiçoe sua carreira com a Academy Abroad"
                bg="bg-home"
                isButton={true}
                element={imgElement}
            />
            
            <HomeOneAbout />

            <CallToActionThree />

            <HowItWorksTwo />     

            <FunFacts />  

            <CourseCarouselThree title="Principais mentores" categoria={todos} />

            {/* <NewsletterThree 
            hSize="h4"
            text="Cadastre em nossa Newsletter e fique por dentro das novidades da Academy."
            btnText="Cadastrar gratuitamente"
            imgPhone={img2}/> */}

            {/* <GiftCardSection /> */}

            <CourseCarouselTwo title="Principais cursos"/>  

            <TestimonialSectionFive />

            <CallToActionFour 
            title="Transforme sua carreira hoje mesmo"
            description="Faça seu cadastro gratuitamente na Academy Abroad e comece aqui a sua jornada de sucesso!"
            link="https://painel.academyabroad.com.br/"
            button="Cadastrar gratuitamente"
            img={img1}/>

            <BhiSection />

            <PartinersSection />

            {/* <Embaixador /> */}

            <FooterOne />   
        </>
    )
}

export default HomeOne;